import React from "react"
import { Link,graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PlaceholderPic from '../components/image'
import Arrow from '../components/arrow'
import HomeBG from '../../static/img/The_Course_of_Empire.jpg'

const IndexContainer = styled.div`
  max-width:1120px;
  margin: 0 auto;
  padding: 0 2em;
  text-align:center;

  a {
     color:black;
     position: relative;
     transition: all 0.3s ease 0s;
     line-height: '22px';
     display:block;
     max-width:250px;
     &:after {
      position: absolute;
      transition: .3s;
      content: '';
      width: 0;
      left: 0%;
      bottom: 0;
      top:34px;
      height: 1px;
      background: #333;
  }
  &:hover {
    &:after {
      width: 100%;
      left: 0%;
      }
  }

  }
  .full-width-image-container {
    width: 100vw;
    height: 95vh;
    position: relative;
    left: 50%;
    right: 50%;
    margin: 0 -50vw;
    background-size: cover;
    background-position: bottom;
    display: grid;
    justify-content: center;
    align-items: center;

    h2{
      max-width: 800px;
      line-height: 1.5;
      @media screen and (max-width:800px) {
        font-size:1.5em;
      }
    }
}

  h2 {
    grid-column: 1 / -1;
    font-size: ${props => props.theme.subHead};

  }
  a {
    margin: 2em auto;
    display: block;
    padding: 10px;
    width: 100%;
    text-align: center;
    color: #000;
    grid-column: 1/-1;
    font-size: 1.2em;
  }
`

const IntroSection = styled.div`
  position:relative;
`
const ContactSection = styled.div`
  margin:2em 0;

  h4 {
    font-size: 1.2em;
    max-width: 550px;
    margin: 1em auto;
    text-align: center;
    line-height:1.3;
  }
`
const Partners = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr ;
  grid-gap:3em;
  margin:3em 0;
  padding:3em 0 0;

  p {
    max-width: 451px;
    margin:0 auto;
  }
  h3 {
    font-size:1.5em;
    margin:1em 0;
  }
  @media screen and (max-width:800px) {
  grid-template-columns: 1fr ;
    }
`

const Companies = styled.div`
  margin:2em 0;
  border-top:1px solid;
  border-bottom:1px solid;
  padding:3em 0;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap:3em;

  @media screen and (max-width:800px) {
  grid-template-columns: 1fr 1fr ;
  grid-gap:2em;

    }

  h2 {
    font-size: ${props => props.theme.subHead};
    grid-column: 1 / -1;
    margin-bottom:1em;
  }

`
const CompanyWebsite = styled.a`
  margin: 2em auto;
  display: block;
  padding: 10px;
  width: auto !important;
  text-align: center;
  color: #000;
  grid-column: auto !important;
  font-size: 1.2em;
`
const Tagline = styled.h1`
  font-size: ${props => props.theme.header};
  color: ${props => props.theme.green};
  margin:1em 0;
`

const TVCDescription = styled.h4`
  font-size:1.8em;
  max-width:750px;
  margin:1em auto;
  line-height:1.5;
`


class BlogIndex extends React.Component {
  constructor(props) {
    super(props)
    this.transitionEnd = this.transitionEnd.bind(this)
    this.mountStyle = this.mountStyle.bind(this)
    this.unMountStyle = this.unMountStyle.bind(this)
    this.state ={ //base css
      show: true,
      style :{
        opacity: 0,
        transition: 'all 2s ease',
      }
    }
  }
  
  componentWillReceiveProps(newProps) { // check for the mounted props
    if(!newProps.mounted)
      return this.unMountStyle() // call outro animation when mounted prop is false
    this.setState({ // remount the node when the mounted prop is true
      show: true
    })
    setTimeout(this.mountStyle, 10) // call the into animation
  }
  
  unMountStyle() { // css for unmount animation
    this.setState({
      style: {
        opacity: 0,
        transition: 'all 1s ease',
      }
    })
  }
  
  mountStyle() { // css for mount animation
    this.setState({
      style: {
        opacity: 1,
        transition: 'all 1s ease',
      }
    })
  }
  
  componentDidMount(){
    setTimeout(this.mountStyle, 10) // call the into animation
  }
  
  transitionEnd(){
    if(!this.props.mounted){ // remove the node on transition end when the mounted prop is false
      this.setState({
        show: false
      })
    }
  }
  
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges
    const siteTitle = data.site.siteMetadata.tagline
    const siteDescription = data.site.siteMetadata.description
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" />
        <IndexContainer style={this.state.style} onTransitionEnd={this.transitionEnd}>
        <IntroSection>
        <div className="full-width-image-container margin-top-0" style={{
       backgroundImage: `url(${
       !!HomeBG
        ? HomeBG
        : HomeBG
        })`,
        }}>
           <h2
             className="has-text-weight-bold is-size-1"
             style={{
             color: 'white',
             padding: '0em 1.6em 0',
             }}
           >
          {siteDescription}
           </h2>
           
         </div>
         </IntroSection>
          {/* <IntroSection>
          <TVCDescription>{siteDescription}</TVCDescription>
          <Link to="/about">Learn More <Arrow /></Link>
          </IntroSection> */}
   
          <Partners>
            <h2>The Team</h2>
           
            <div>
              <h3>David Bates</h3>
              <p>  Focuses on strategy, manufacturing, product, and operations. Former investor at Morningside Ventures and former Chief Strategy Officer at F-Wave. Cofounder of hotel technology company. Auckland PhD in Chemical and Materials Engineering.  </p>
            </div>
            <div>
              <h3>Justin Yap</h3>
              <p> Former International Finance Corporation and World Bank and former corporate lawyer. Harvard JD. </p>
            </div>
            <div>
              <h3>Seng Oon Toh</h3>
              <p> Focuses on  anything technical. Former ARM and AMD engineer;  founding CTO of machine-learning company. Berkeley PhD in Electrical Engineering and Computer Science. </p>
            </div>
            <div>
              <h3> Ed Walters</h3>
              <p> Focuses on fundraising, hiring, and business development/sales. Former investor at Morningside Ventures and former vice president of business development for Bode, a tech-enabled hospitality management company. Harvard MBA. </p>
            </div>
            
            <Link to="/about">Learn More <Arrow /></Link>
          </Partners>
          <Companies>
            <h2>Companies</h2>
            {posts.map(({ node }) => {
                if (node.frontmatter.templateKey === "blog-post") {
                  return (
                <CompanyWebsite href={node.frontmatter.website} name={node.frontmatter.title} target="_blank">
                  <h3>{node.frontmatter.title}</h3>
                </CompanyWebsite>
              )
            }
          })}

            <Link to="/portfolio">See All Companies  <Arrow /></Link>
          </Companies>
          <ContactSection>
            <h2>Contact Us</h2>
            <h4> If you are looking for investment, please include a PDF of your investment deck, a brief description of how much you are looking to raise, how much you have raised historically, and why your company is a relevant investment target for our firm.</h4>
            <a href="mailto:info@tamarisc.com">info@tamarisc.com</a>
          </ContactSection>
        </IndexContainer>

      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        tagline
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }
      ) {
      edges {
        node {
            html
          excerpt
          fields {
            slug
          }
          frontmatter {
            templateKey
            date(formatString: "MMMM DD, YYYY")
            title
            website
          }
        }
      }
    }
  }
`
